@import '../../../../config/variables.scss';

.delete-client {
  .actions {
    display: flex;
    justify-content: flex-end;

    button.delete-button {
      background-color: $red-color;
    }
  }
}
