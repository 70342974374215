@import '../../../config/variables.scss';

.navbar {
  background-color: $dark-background-color;
  color: white;
  position: relative;
  height: 40px;

  img {
    margin: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .controls {
    position: absolute;
    top: 7px;
    right: 9px;
    font-size: 13px;
    line-height: 20px;

    .icon {
      position: absolute;
      right: 0px;
      top: -6px;
    }

    .username {
      margin-right: 24px;
    }
  }

  .icon {
    height: 32px;

    &.back {
      font-size: 24px;
      margin-top: 4px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
