.branding-element {
  margin-top: 16px;
  display: grid;

  .template {
    margin-top: 4px;
    width: 100%;
  }

  .file {
    margin-top: 8px;
    width: calc(100% - 2px);
    height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 0px 0px 1px #ffffff,
      inset 0px 3px 5px -2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;

    .preview {
      max-height: 80%;
      max-width: 80%;
      height: auto;
      width: auto;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .loading {
      position: initial;
    }
  }

  .actions {
    margin-top: 8px;
    display: flex;

    .new-file {
      width: 100%;
      margin-left: 0;
      text-align: center;
    }

    .remove-file {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
