.public-key {
  .body {
    display: flex;

    .subsection {
      width: 50%;
    }
  }

  .controls {
    display: flex;
    margin-top: 16px;

    .info-toast {
      margin-right: 16px;
      margin-top: 0;
      width: 100%;
    }

    button.primary-button.upload-file {
      margin-top: 0;
      height: 32px;
    }
  }
}
