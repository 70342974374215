.user-list {
  .content {
    width: 100%;

    .form,
    .email {
      width: 100%;
    }

    .form-group {
      width: calc(100% - 164px);
    }

    .add-email,
    .upload-mail-list {
      width: max-content;
      margin-right: 0;
      margin-left: auto;
      margin-top: 38px;
    }
  }
}
