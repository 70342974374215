.new-client {
  padding: 32px;
  width: 580px;

  .subhead {
    margin-top: 0;
  }
}

.abbrevation {
  flex-basis: 100px;
  padding-right: 16px;
}

.name {
  flex-basis: calc(100% - 100px);
}
