$margin: calc((100% - 720px) / 4);

.branding-content {
  display: flex;

  .LOGO,
  .BACKGROUND,
  .ICON {
    width: 100%;
    max-width: 240px;
  }

  .LOGO {
    margin-left: 0;
    margin-right: $margin;
  }

  .BACKGROUND {
    margin-left: $margin;
    margin-right: $margin;
  }

  .ICON {
    margin-left: $margin;
    margin-right: 0;
  }

  .controls {
    display: block;
    margin-top: 32px;
  }
}
