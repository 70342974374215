@import '../../config/variables.scss';

.clients {
  .box {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    background: white;
  }

  .tabs {
    width: 100%;
    display: flex;
    color: $black-color;
    text-transform: uppercase;
    border-bottom: 1px solid $border-color;

    div {
      width: 50%;
      text-align: center;
      padding: 11px 0 9px;

      &.active {
        border-bottom: 2px solid $dark-background-color;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .section {
    margin-top: 24px;
    padding: 32px;

    .head {
      display: flex;

      .id {
        margin-right: 0;
        margin-left: auto;
      }
    }

    .subhead {
      margin: 0;
    }
  }
}
