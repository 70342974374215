@import '../../../../config/variables.scss';

.activity-list {
  margin-top: 24px;
  font-size: 13px;
  line-height: 14px;

  .table-head,
  .table-row {
    width: 100%;
    display: flex;
    border: 1px solid $gray-color;

    div {
      padding: 10px;
    }
  }

  .table-head {
    background-color: $light-blue-color;
    font-weight: 600;
  }

  .created-date {
    width: 18%;
  }

  .type {
    width: 30%;
    border-left: 1px solid $gray-color;
    border-right: 1px solid $gray-color;
  }

  .message {
    width: 52%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .table-row {
    background-color: white;
  }

  .table-footer {
    margin-top: 24px;
    display: flex;
    align-items: center;

    .number-results {
      width: 15%;
    }

    .pagination-wrapper {
      width: 70%;
    }

    ul.pagination {
      margin: 0;
    }

    .go-to {
      margin-right: 0;
      margin-left: auto;
      display: flex;
      align-items: center;

      label {
        width: max-content;
        margin-right: 16px;
      }

      input.input-form {
        width: 32px;
        text-align: center;
      }

      .form-group {
        margin-top: 0;
      }
    }
  }

  .table {
    position: relative;

    .no-activities {
      text-align: center;
      font-size: 26px;
      height: 250px;
      margin-top: 48px;
    }
  }
}
