@import '../../config/variables.scss';

.clients {
  text-align: left;
  width: 80vw;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;

  .outline-button {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;

    .add-client {
      margin-right: 4px;
    }
  }

  .client {
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17.6px;
    font-weight: 600;
    display: flex;

    .more-icon {
      margin-right: 2px;
      margin-left: auto;
      color: $primary-color;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  h1 {
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

.pagination-container {
  text-align: center;
  user-select: none;

  ul {
    display: inline-block;

    li {
      display: inline-block;
      border: solid 1px transparent;
      width: 30px;
      height: 30px;
      font-size: 14px;
      line-height: 18px;
      position: relative;
      margin: 2px;

      a {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      a:focus {
        outline: 0;
      }
    }
  }

  .pagination {
    .previous,
    .next {
      a {
        font-size: 16px;
      }
      i {
        vertical-align: text-bottom;
      }
      &:hover {
        a {
          color: black;
        }
      }
    }

    .active {
      opacity: 0.45;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 2px;

      &:hover {
        cursor: default;
      }
    }

    .break a {
      cursor: default;
    }
  }
}
