@import '../../../../../../config/variables.scss';

$border: 1px solid $border-color;

.user-table {
  margin-top: -32px;

  .table-head {
    background-color: $light-blue-color;
    display: flex;
    margin-top: 32px;
    border-top: $border;
    border-bottom: $border;
  }

  .table {
    .row {
      display: flex;
      border-bottom: $border;
    }
  }

  .table .row,
  .table-head {
    div {
      padding: 8px;

      svg {
        margin-top: 1px;
        width: 16px;
        height: 16px;
      }

      &.email,
      &.mail {
        border-left: $border;
      }

      &.delete,
      &.mail {
        border-right: $border;

        &:hover {
          cursor: pointer;
        }
      }

      &.mail {
        width: 124px;
        display: flex;
        padding: 8px 12px;

        span {
          margin-left: 4px;
        }

        &.now {
          color: $green-color;
        }
      }

      &.email {
        display: flex;
        span.modified {
          margin-right: 4px;
          margin-left: auto;
          color: $gray-color;
        }
      }
    }
  }
}
