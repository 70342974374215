@import '../../../../config/variables.scss';

.reporting-emails {
  position: relative;

  .add-email {
    position: absolute;
    top: 6px;
    right: 32px;
    width: calc(50% - 48px);

    .form {
      width: 100%;

      .inline-form-group {
        width: 100%;

        input.input-form {
          width: calc(100% - 64px);
        }
      }
    }
  }

  .client-emails {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .email {
      border: 1px solid $border-color;
      border-radius: 18px;
      padding: 6px 6px 6px 8px;
      margin-right: 16px;
      display: flex;

      .remove-email {
        width: 20px;
        height: 20px;
        background: #dedede;
        border-radius: 10px;
        position: relative;
        margin-left: 6px;

        svg {
          width: 16px;
          height: 16px;
          margin: 2px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
