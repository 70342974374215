@import './config/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

body {
  background-color: $background-color;
  font-family: $font-family;
  font-size: 14px;
}

.login-box {
  text-align: center;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 450px;
  min-width: 280px;
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.primary-button {
  background: $primary-color;
  border-radius: 2px;
  color: white;
  padding: 10.5px 0;
  border: none;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.content-box {
  margin: 32px auto;
}

h1 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.005em;
  color: black;
}

div.outline-button {
  color: $primary-color;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.003em;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  border-radius: 2px;
  width: max-content;
  padding: 6px;

  &:hover {
    cursor: pointer;
  }
}

h2.subhead {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.form {
  display: flex;
  flex-flow: row wrap;

  .form-group {
    margin-top: 16px;
    display: inline-block;
    box-sizing: border-box;
  }

  .inline-form-group {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .input-form {
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.45);
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    width: calc(100% - 18px);
    margin-top: 4px;
    padding: 7px 8px;
  }

  .form-group,
  .inline-form-group {
    input,
    textarea,
    select {
      font-family: $font-family;

      &.input-form {
        height: 16px;
        border: 1px solid $border-color;
        box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        width: calc(100% - 18px);
        margin-top: 4px;
        padding: 7px 8px;

        &.error {
          border: 1px solid $red-color;
          box-shadow: inset 0px 2px 2px rgba($red-color, 0.25);

          &:focus-visible {
            outline: none;
          }
        }
      }

      &:disabled {
        border: 1px solid $disabled-color;
        background: $disabled-color;
        box-shadow: none;
      }
    }

    input {
      &[type='checkbox'] {
        height: 16px;
        width: 16px;
      }

      &.small {
        width: 32px;
        text-align: center;
      }
    }

    &.half {
      flex-basis: 50%;
    }

    &.full {
      flex-basis: 100%;
    }

    select.input-form {
      height: 32px;
      width: 100%;
      padding: 6px 8px;
    }
  }
}

button.primary-button {
  width: max-content;
  padding: 8px;
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-top: 16px;
}

.left {
  padding-right: 16px;
}

.right {
  padding-left: 16px;
}

.switch-group {
  display: flex;
  margin-top: 4px;
}

.switch-button {
  border-radius: 24px;
  border: 1px solid $border-color;
  padding: 7px 8px;
  margin-right: 16px;

  &.active {
    background-color: $primary-color;
    border: none;
    padding: 8px;

    span {
      color: white;
    }
  }

  &:hover {
    cursor: pointer;
  }

  input {
    display: none;
  }
}

// For hiding arrows in number fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.secondary-button {
  color: $primary-color;
  background: $disabled-color;
  border-radius: 2px;
  padding: 8px;
  height: 16px;
  width: 16px;
  margin: 4px 0 0 16px;

  svg {
    height: 16px;
    width: 16px;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.gray-button {
  background: $disabled-color;
  border-radius: 2px;
  color: #404040;
  padding: 8px;
}

.delete-button {
  background: $red-color;
  border-radius: 2px;
  color: white;
  padding: 8px;
}

.gray-button,
.secondary-button,
.delete-button {
  border: none;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

label {
  color: #404040;
  font-size: 13px;
  line-height: 16px;

  &.checkbox-label {
    margin-left: 4px;
  }
}

.file-group {
  input[type='file'] {
    display: none;
  }

  .file-wrapper {
    color: rgba(0, 0, 0, 0.35);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;

    .upload-icon {
      margin-right: 0;
      margin-left: auto;
      color: #404040;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

input::placeholder {
  color: rgba(0, 0, 0, 0.35);
}

.remove-icon {
  color: $red-color;
  padding: 0 8px;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}
