.wrapper {
  .content {
    background: white;
    padding: 40px;

    .header {
      display: flex;

      .icon {
        width: 50px;
        height: 50px;
      }

      .title {
        display: grid;
        padding-left: 16px;

        strong {
          font-weight: 600;
          font-size: 20px;
          line-height: 125%;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 110%;
          text-transform: uppercase;
        }
      }
    }
  }
}
