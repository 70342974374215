.details-content {
  display: flex;

  .left {
    width: 75%;

    .form {
      width: 100%;
      flex-flow: row;

      .form-group {
        width: calc(100% - 86px);
        display: grid;

        .input-form {
          width: calc(100% - 32px);
        }
      }

      .secondary-button {
        width: 70px;
        text-align: center;
        margin: 36px 0 0 auto;
      }
    }
  }

  .right {
    width: 25%;

    .qr-code {
      width: 60%;
      margin: 20px auto;
      display: block;
    }
  }
}
