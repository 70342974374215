.download.btn {
  text-align: center;
  padding: 10px 24px;
  gap: 10px;
  margin-top: 40px;
  background: #2b5abc;
  border-radius: 100px;
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.1px;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.instructions {
  margin-top: 50px;

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &.uppercase {
      text-transform: uppercase;
    }
  }

  hr {
    width: calc(100vw - 2px);
    margin-left: -40px;
    background-color: #bfbfbf;
    height: 1px;
    border: none;
  }

  .content-section {
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 4px;
      width: 20px;
      height: auto;
    }

    .content-text {
      margin-bottom: 32px;
    }
  }
}

.content-section h3 {
  text-transform: none;
}
