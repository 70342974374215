@import '../../../config/variables.scss';

.toast {
  margin: 8px 0;
  padding: 7px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.13);
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;

  svg {
    margin: 0 8px 0 6px;
  }

  &.error-toast {
    color: $red-color;
    background: #ffd9d9;
    border-left: 2px solid $red-color;
  }

  &.general {
    width: max-content;
    min-width: 280px;
    max-width: 1024px;
    position: fixed;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);

    .message {
      padding-right: 8px;
    }
  }

  &.info-toast {
    color: $primary-color;
    border-left: 2px solid $primary-color;
    background: #ecf1fe;

    .message {
      color: black;
    }
  }

  svg.close-toast {
    margin-right: 8px;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }
}
