@import '../../../../config/variables.scss';

.clients .client-files.section {
  padding: 0;

  .content {
    padding: 32px;
  }

  .subsection {
    width: 50%;
    display: flex;

    .language {
      width: 40%;
      padding-right: 32px;
    }

    .title {
      width: 60%;
      padding-right: 16px;
    }

    .file {
      padding-left: 16px;
      width: calc(100% - 48px);
    }

    .secondary-button {
      margin-top: auto;
    }
  }

  hr {
    border: 1px solid #dedede;
    margin: 0;
    width: 100%;
  }

  .client-file {
    display: flex;
    padding: 16px 32px;
    width: calc(100% - 64px);

    .language {
      width: calc(40% - 24px);
    }

    .subsection div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file {
      color: $primary-color;
    }
  }

  .right {
    margin-right: 48px;
    margin-left: auto;
  }
}
