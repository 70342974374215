@import '../../../../../../config/variables.scss';
form {
  width: 100%;

  .form {
    width: 100%;

    .colors {
      display: flex;
      width: 100%;

      .form-group {
        width: calc(100% - 40px);
      }

      .color-preview-wrapper {
        margin-top: 38px;
        margin-right: 0;
        margin-left: auto;
        height: 30px;
        width: 30px;
        border: 1px solid $light-gray-color;
        box-shadow: inset 0px 0px 0px 1px #ffffff,
          inset 0px 3px 5px -2px rgba(0, 0, 0, 0.2);
        border-radius: 2px;

        .color-preview {
          width: 22px;
          height: 22px;
          margin: 4px;
        }
      }
    }
  }
}
