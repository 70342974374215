@import '../../../config/variables.scss';

.delete-modal {
  padding: 32px;
  width: 264px;
  white-space: pre-line;

  &.multiple {
    width: 496px;

    .confirm {
      margin-top: 16px;
      display: grid;

      .confirm-text {
        font-size: 11px;
      }

      .delete-text {
        font-size: 12px;
        background-color: $red-color;
        color: white;
        width: max-content;
        padding: 2px 4px;
      }
    }
  }

  .form,
  .form-group,
  .input-form {
    width: 100%;
  }

  .form {
    .form-group {
      margin-top: 4px;
    }
  }

  .actions {
    margin-top: 16px;
    width: 100%;
    display: flex;

    .delete-button {
      margin-right: 0;
      margin-left: auto;

      &:disabled {
        opacity: 0.5;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
