.mobile.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: white;

  .logo-header {
    width: 100vw;
    padding: 132px 0 80px;

    img {
      max-width: 80%;
      max-height: 100px;
      display: block;
      margin: 0 auto;
    }
  }
}
