.reporting {
  display: flex;

  .subsection {
    width: 50%;

    &.right {
      position: relative;
    }
  }

  .report-size {
    margin-right: 0;
    margin-left: auto;

    label {
      margin-right: 16px;
    }
  }

  .decryption {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .form-group {
    textarea.input-form.email-text {
      height: 160px;
      resize: vertical;
    }
  }

  .email-toogle {
    position: absolute;
    right: 0;
    top: 0;
  }
}
