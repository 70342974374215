.download-mobile-page {
  width: 100vw;
  height: 100vh;
  position: relative;

  .desktop.wrapper {
    width: 20%;
    min-width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      width: 100%;
      position: relative;
      padding-top: 80%;

      .logo-image {
        width: 80%;
        max-width: 15vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      small {
        margin: 0 auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        letter-spacing: 0.03em;
        color: #7f7f7f;
        width: 75%;
        display: block;
        text-align: center;
      }
    }
  }
}
