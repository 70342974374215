@import '../../../../../config/variables';

.progress {
  margin-top: 16px;
  text-align: center;

  .wrapper {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 16px;
    overflow: hidden;
  }

  .bar {
    height: 20px;
    background-color: $primary-color;
    transition: width 0.5s ease;
  }

  p {
    margin-top: 4px;
  }
}
