@import '../../config/variables.scss';

.downloads {
  position: relative;
  height: calc(100vh - 40px);

  .wrapper {
    position: absolute;
    width: 70%;
    min-width: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;

    .page-title {
      width: 100%;
      text-align: center;
    }

    .applications {
      display: flex;

      .application {
        width: 33%;
        aspect-ratio: 1;
        display: block;
        background-color: white;
        margin: 16px;
        position: relative;
        box-shadow: 0px 0px 3px rgb(0 0 0 / 40%);

        .platform {
          display: block;
          margin: 0 auto;
          width: 45%;
          max-width: 33vw;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.01);
        }

        &.disabled {
          opacity: 0.5;

          &:hover {
            cursor: not-allowed;
          }
        }
      }

      .title {
        text-align: center;
        width: 100%;
        margin-top: 16px;
        font-size: 24px;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
      }

      .download-icon {
        position: absolute;
        right: 8px;
        top: 8px;
        height: 24px;
        width: 24px;
        color: $light-gray-color;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .downloads {
    .wrapper {
      width: 100%;

      .applications {
        .title {
          bottom: 8px;
          font-size: 16px;
        }

        .download-icon {
          width: 16px;
        }
      }
    }
  }
}
