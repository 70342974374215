.loading {
  width: 100%;
  height: calc(100vh - 104px);
  position: relative;

  img.loading-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
  }
}
