$primary-color: #2b5abc;
$background-color: #f2f2f2;
$dark-background-color: #00003c;
$light-gray-color: rgba(0, 0, 0, 0.35);
$border-color: #dedede;
$disabled-color: rgba(0, 0, 0, 0.07);
$red-color: #e01616;
$gray-color: #bfbfbf;
$black-color: #000000;
$light-blue-color: #ecf1fe;
$green-color: #118330;

$font-family: 'Source Sans Pro', serif;
