@import '../../config/variables.scss';

.login-wrapper .logo {
  margin: 40px auto 32px;
}

.login-wrapper .primary-button {
  width: calc(100% - 64px);
  max-width: 300px;
  margin: 0 auto 40px;
  font-size: 15px;
  line-height: 19px;
}

.login-wrapper .login-button {
  width: calc(100% - 64px);
  max-width: 300px;
  margin: 0 auto 24px;
}
