@import '../../../../config/variables.scss';

.software-key {
  position: relative;
  display: flex;

  .body {
    margin-top: 16px;
  }

  .subsection {
    &.input {
      width: 100%;
    }

    &.buttons {
      .gray-button {
        width: max-content;
        letter-spacing: 0.003em;
        padding: 8px;
        margin-top: 23px;
      }

      .primary-button {
        width: 100%;
        margin-top: 0;
      }
    }
  }

  .inline-group {
    display: flex;

    .download-key {
      display: flex;
      background: $disabled-color;
      border-radius: 2px;
      padding: 7px 8px;
      width: calc(100% - 32px);
      color: $light-gray-color;

      .remove {
        margin-right: 8px;
        margin-left: auto;
        color: black;

        svg {
          margin-top: 2px;
          color: black;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
